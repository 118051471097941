import { GetStaticPaths, GetStaticPathsResult, GetStaticProps } from 'next'
import StoryblokService from '@service/StoryblokService'
import { getLayout } from '@utils/getLayout'
import { getWithDefaultProps } from '@utils/getWithDefaultProps'
import { removeLastFromSlug, REVALIDATE } from 'common'
import { ExtendedNextPage } from './types'
import { STORYBLOK_IGNORE_LIST } from 'service'
import { isEnglish, toEnglish } from '@utils/locale'
import {
	MainNestablePage,
	NestablePage,
	RegularPage,
	RegularPageProps,
} from '@components/ui/Pages'
import { nestablePageName } from '@utils/nestablePageName'
import { Language } from '@constants/language'
import { getStoryblokApi } from '@storyblok/react'

type PageProps =
	| ({
			pageType: 'page'
	  } & RegularPageProps)
	| ({
			pageType: 'main_nestable_page'
	  } & MainNestablePage)
	| ({
			pageType: 'nestable_page'
	  } & NestablePage)

const Page: ExtendedNextPage<PageProps> = (props) => {
	if (props.pageType === 'main_nestable_page') {
		return <MainNestablePage {...props} />
	}

	if (props.pageType === 'nestable_page') {
		return <NestablePage {...props} />
	}

	return <RegularPage {...props} />
}

export const getStaticProps = getWithDefaultProps<GetStaticProps<PageProps>>(
	async ({ params, locale }) => {
		console.log('~~~1212-1', JSON.stringify(params || {}))
		const { slug: _slug = '' } = params ?? {}

		const slug: string = typeof _slug === 'string' ? _slug : _slug.join('/')

		const getSlug = `/${slug}` as const

		if (
			STORYBLOK_IGNORE_LIST.some((slugToIgnore) =>
				getSlug.includes(slugToIgnore)
			)
		) {
			return {
				notFound: true,
				revalidate: REVALIDATE.MEDIUM,
			}
		}

		const storyblokInstance = StoryblokService.getInstance(locale)
		const story = await storyblokInstance.getStory<Record<string, any>>(
			getSlug,
			{
				cv: 1,
			}
		)
		console.log('~~~story cv:1', JSON.stringify(story?.content || {}))
		const story2 = await storyblokInstance.getStory<Record<string, any>>(
			getSlug,
			{
				cv: 30_000,
			}
		)
		console.log('~~~story2 cv:30_000', JSON.stringify(story2?.content || {}))
		const story3 = await storyblokInstance.getStory<Record<string, any>>(
			getSlug,
			{
				cv: new Date().getTime(),
			}
		)

		console.log(
			'~~~story3 cv:' + new Date().getTime(),
			JSON.stringify(story3?.content || {})
		)

		if (!story) {
			return {
				notFound: true,
				revalidate: REVALIDATE.MEDIUM,
			}
		}

		try {
			const getStoryblokApiGetStory = await getStoryblokApi().getStory(
				`/sportsaustria${getSlug}`,
				{
					version: 'published',
					cv: 30_000,
				}
			)

			console.log(
				'~~~getStoryblokApiGetStory cv:30_000',
				JSON.stringify(getStoryblokApiGetStory.data.story.content)
			)
		} catch (e: any) {
			console.log('~~~getStory message', e?.message)
			console.log('~~~getStory response message', e?.response?.message)
		}

		try {
			const getStoryblokApiGet = await getStoryblokApi().get(
				`cdn/stories/sportsaustria${getSlug}`,
				{
					version: 'published',
					cv: new Date().getTime(),
				}
			)

			console.log(
				'~~~getStoryblokApiGet cv:' + new Date().getTime(),
				JSON.stringify(getStoryblokApiGet.data.story.content)
			)
		} catch (e: any) {
			console.log('~~~get message', e?.message)
			console.log('~~~get response message', e?.response?.message)
		}

		if (story.content.component === 'main_nestable_page') {
			const links = await storyblokInstance.getSideMenuLinks(getSlug)

			return {
				props: {
					pageType: 'main_nestable_page',
					sideMenuLinks: links,
					header: story.content.header?.[0] || null,
					body: story.content.body || null,
				},
				revalidate: REVALIDATE.MEDIUM,
			}
		}

		if (story.content.component === 'nestable_page') {
			const slug = removeLastFromSlug(getSlug)

			const links = await storyblokInstance.getSideMenuLinks(
				slug as `/${string}`
			)

			const startPageStory = await storyblokInstance.getStory<
				Record<string, any>
			>(slug as `/${string}`)

			if (!startPageStory || !startPageStory.content) {
				return {
					notFound: true,
					revalidate: REVALIDATE.MEDIUM,
				}
			}

			const name = nestablePageName(
				story.name,
				story.translated_slugs,
				locale as Language
			)

			return {
				props: {
					pageType: 'nestable_page',
					name,
					sideMenuLinks: links,
					order: story.content.order,
					description: story.content.description || null,
					header: startPageStory.content.header?.[0] || null,
					body: story.content.body || null,
				},
				revalidate: REVALIDATE.MEDIUM,
			}
		}

		return {
			props: {
				pageType: 'page',
				header: story.content.header?.[0] || null,
				body: story.content.body || null,
			},
			revalidate: 60 * 60 * 2,
		}
	}
)

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
	const paths: GetStaticPathsResult<{ slug: Array<string> }>['paths'] = []

	for await (const locale of locales || []) {
		if (isEnglish(locale)) {
			continue
		}

		const storyblokInstance = StoryblokService.getInstance(locale)
		const links = await storyblokInstance.getLinks()

		if (!links) {
			break
		}

		Object.values(links).forEach(({ slug, is_folder }) => {
			if (
				!slug ||
				is_folder ||
				STORYBLOK_IGNORE_LIST.some((slugToIgnore) =>
					slug.includes(slugToIgnore)
				)
			) {
				return
			}

			const slugs = slug.split('/')
			slugs.shift()

			paths.push({ params: { slug: slugs }, locale })
			paths.push({ params: { slug: slugs }, locale: toEnglish(locale) })
		})
	}

	return {
		paths,
		fallback: 'blocking',
	}
}

Page.getLayout = getLayout

export default Page
