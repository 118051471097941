import { Noop, StoryblokSectionHeader, StoryblokSectionHeaderProps } from 'ui'
import { StoryContentType, ISbComponentType } from 'service'
import DynamicComponent from '@components/common/DynamicComponents/DynamicComponent'
import { useReloadPrivacyPage } from 'common'

export type RegularPageProps = {
	header?: StoryContentType<StoryblokSectionHeaderProps['blok']> | null
	body?: Array<ISbComponentType> | null
}

export function RegularPage({ header, body }: RegularPageProps) {
	useReloadPrivacyPage()

	const renderHeader = () => {
		if (!header) {
			return <Noop />
		}

		return <StoryblokSectionHeader blok={header} type="header" />
	}

	return (
		<>
			{renderHeader()}
			{body?.map((item) => (
				<DynamicComponent key={item._uid} blok={item} />
			))}
		</>
	)
}
