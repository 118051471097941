import SmallCard from '@components/ui/SmallCard'
import { addRomanNumeralToText } from 'common'
import { ISbComponentType, SideMenuLink } from 'service'
import {
	Container,
	StoryblokSectionHeader,
	StoryblokSectionHeaderProps,
} from 'ui'
import DynamicComponent from '@components/common/DynamicComponents/DynamicComponent'
import { SideMenu } from '@components/common/SideMenu'
import { useTranslation } from 'react-i18next'

export type MainNestablePage = {
	sideMenuLinks: Array<SideMenuLink>
	header?: StoryblokSectionHeaderProps['blok'] | null
	body?: Array<ISbComponentType> | null // Might be just description
}

export function MainNestablePage({
	header,
	body,
	sideMenuLinks,
}: MainNestablePage) {
	const { t } = useTranslation()

	return (
		<Container>
			{!!header && <StoryblokSectionHeader blok={header} type="header" />}
			<div className="pageSection flex gap-6 sm:flex-col">
				<div className="w-2/3 sm:w-full">
					{body?.map((item) => (
						<DynamicComponent key={item._uid} blok={item} />
					))}
					<div className="grid grid-cols-2 gap-6 sm:grid-cols-1">
						{sideMenuLinks.map((item, idx) => {
							if (idx === 0) {
								return
							}
							return (
								<SmallCard
									key={item.fullSlug}
									blok={{
										title: addRomanNumeralToText(item.pageName, idx),
										color: 'white',
										image_multiply_effect: false,
										button_text: t('Read More') as string,
										button_url: item.fullSlug,
									}}
								/>
							)
						})}
					</div>
				</div>
				<div className="w-1/3 sm:w-full">
					<SideMenu links={sideMenuLinks} />
				</div>
			</div>
		</Container>
	)
}
