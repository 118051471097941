import { useRouter } from 'next/router'
import clsx from 'clsx'
import { SideMenuLink } from 'service'
import { addRomanNumeralToText } from 'common'
import { Sheet, PanelButton } from 'ui'

interface SideMenuProps {
	links: Array<SideMenuLink>
}

export const SideMenu = ({ links }: SideMenuProps) => {
	const router = useRouter()
	const splitSlugs = router.asPath.split('/')
	const lastSlug = splitSlugs[splitSlugs.length - 1] as string

	return (
		<div className="relative h-full">
			<div className="sticky top-[104px]">
				<PanelButton buttonText="Seiten" iconPosition="right" icon={<Sheet />}>
					<div className="flex w-full flex-col gap-y-2 bg-white md:px-6 md:py-6 lg:px-8 lg:py-6">
						{links.map(({ pageName, fullSlug }, idx) => {
							const isActive =
								fullSlug.endsWith(lastSlug) || fullSlug.endsWith(`${lastSlug}/`)

							return (
								<PanelButton.ToggleAbstractButton
									key={pageName}
									href={fullSlug}
									className={clsx(
										'text-primary text-left',
										isActive && 'p_highlight'
									)}
								>
									{addRomanNumeralToText(pageName, idx)}
								</PanelButton.ToggleAbstractButton>
							)
						})}
					</div>
				</PanelButton>
			</div>
		</div>
	)
}
