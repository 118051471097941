import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import { ISbComponentType, SideMenuLink } from 'service'
import {
	Container,
	StoryblokSectionHeader,
	StoryblokSectionHeaderProps,
} from 'ui'
import RichTextResolverService from 'service/RichTextResolverService'
import DynamicComponent from '@components/common/DynamicComponents/DynamicComponent'
import NavigationButtons from '@components/ui/NavigationButtons'
import { SideMenu } from '@components/common/SideMenu'
import { useReloadPrivacyPage } from 'common'

export type NestablePage = {
	name: string
	order: `${number}`
	sideMenuLinks: Array<SideMenuLink>
	description: StoryblokRichtext | null
	body: Array<ISbComponentType> | null
	header: StoryblokSectionHeaderProps['blok'] | null
}

export function NestablePage({
	name,
	order,
	sideMenuLinks,
	description,
	body,
	header,
}: NestablePage) {
	useReloadPrivacyPage()

	return (
		<Container>
			{!!header && <StoryblokSectionHeader blok={header} type="header" />}
			<div className="flex gap-6 sm:flex-col">
				<div className="h-full sm:w-full md:w-2/3 lg:w-2/3">
					<h3 className="mb-6">{name}</h3>
					{description && (
						<div className="mb-12">
							{new RichTextResolverService(description).resolveRichText()}
						</div>
					)}
					{body?.map((item) => (
						<DynamicComponent key={item._uid} blok={item} />
					))}
					<NavigationButtons
						order={Number(order)}
						sideMenuLinks={sideMenuLinks}
					/>
				</div>
				<div className="sm:w-full md:w-1/3 lg:w-1/3">
					<SideMenu links={sideMenuLinks} />
				</div>
			</div>
		</Container>
	)
}
